header {
  height: 100vh;
  padding-top:5rem;
  overflow: hidden;
}

.header__container {
  height: 100%;
  text-align: center;
  position: relative;
  /* margin-top: 4rem !important; */
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.me {
  width: 18rem;
  height: 20.8rem;
  position: absolute;
  left: calc(50% - 9rem);
  background-color: lightblue;
  padding:2rem 0rem 0rem 0rem;
  border-radius:5rem;
  margin-top:2rem;
  /* bottom:0rem; */
  overflow: hidden;
  position:relative;
  background-image: linear-gradient(to right top, #ffd365, #ffa060, #ee716c, #c74e7c, #8b3d85, #6b3476, #4e2a65, #322052, #311638, #281023, #1b0913, #000000);
}


.btn{
  width:max-content;
  border:1px solid cyan;
  padding:1rem;
  border-radius: 0.3rem;
}

.scroll__down_txt{
  position: absolute;
  right: -1.5rem;
  display: flex;
  transform: rotate(90deg);
  bottom: 5rem;
  font-size: 0.8rem;
  color: cyan;
}

.hero-name{
  background: #FFD365;
  display: inline;
  color: #212121;
}
