.social__icons {
  display: flex;
  justify-content: center;
  gap: 0.6rem;
  flex-direction: column;
  position: absolute;
  bottom: 2rem;
  align-items: center;
}

.social__icons::after {
  content: "";
  width: 1px;
  height: 5rem;
  background: white;
}
.social_img {
  width: 1.2rem;
  cursor: pointer;
}

.icn-size{
  font-size: 1.2rem;
  color: cyan;
}
