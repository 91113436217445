.contact {
  overflow: hidden;
}

.contact__container {
  height: 100vh;
}

.card-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.card {
  display: flex;
  border: 1px solid white;
  /* padding: 3px; */
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  min-width: 15rem;
}

.social_link {
  font-size: 1rem;
  padding-right: 1rem;
}

.social_icns {
  font-size: 3rem;
  color: cyan;
}
