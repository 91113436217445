.nav{
    background: rgba(0,0,0,0.3);
    width: max-content;
    padding: 0.5rem;
    z-index: 2;
    position: fixed;
    display: flex;
    gap: 2rem;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.5rem;
}

.nav a{
    background: #FFD365;
    padding: 0.2rem;
    border-radius: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
}