.logo{
    width: 100%;
    top: 0rem;
    position: fixed;
    /* top: 1rem; */
    text-align: center;
    display: block;
    /* border-bottom: 1px solid #8d8d8d; */
    padding: 1rem;
    padding-left: 0rem;
    /* border-bottom: 1px solid rgba(9,50,121,1); */
    z-index: 999;
    background: linear-gradient(90deg, rgba(9,50,121,1) 3%, rgba(81,79,130,1) 100%, rgba(0,212,255,1) 100%);
}

.app-logo{
    width: 12rem !important;
    display: inline !important;
    text-align: center;
}