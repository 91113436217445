.skill {
  /* height: 100vh; */
  /* padding-top: 5rem; */
  overflow: hidden;
}

.skill__container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  justify-content: center;
  margin-top: 2rem;
}

.icon-bg {
  font-size: 5rem;
  background: #0a1437;
  color: lightblue;
  padding:0.3rem;
  border:3px solid #FFF;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.icon-bg:hover{
  color:lightcoral;
}
