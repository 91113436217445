.testimonial {
  height: 100vh;
  /* padding-top: 5rem; */
  overflow: hidden;
}

.testimonial__container {
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


/* inner parent allowed to stretch to fit children */
#flex-scroll {
  padding: 30px 20px;
  display: flex;
  /* justify-content: center; */
  flex-wrap: nowrap;
  gap: 0.9rem;
  text-align: justify;
  width: 100%;
  overflow: auto;
}

.box {
  position: relative;
  min-width: 300px;
  height: 400px;
  line-height: 1.7;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.box .content{
  padding:20px;
  color:#fff;
  overflow: auto;
  background: #0a1437;
  font-size: 0.9rem;
}

.author{
  background: #122462;
}